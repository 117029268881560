import { Button, Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import notificationService from 'common/services/notification-service/notification-service';
import { useState } from 'react';

export const SMSNotifications = () => {
  const [form] = Form.useForm();
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [isSMSButtonDisabled, setIsSMSButtonDisabled] = useState<boolean>(false);

  const handleSendNotification = async (values: { message: string }) => {
    try {
      setIsSMSButtonDisabled(true);
      await notificationService.sendSMSMassNotification(values.message.trim());
      setIsSMSButtonDisabled(false);
      notification.success({
        message: 'Success',
        description: 'SMS notification sent successfully!',
        duration: 1.5,
      });
    } catch (error) {
      console.error('Failed to send SMS notification:', error);
      setIsSMSButtonDisabled(false);
    }
  };

  const handleValuesChange = (changedValues: any) => {
    const message = (changedValues.message || '').trim();
    setIsFormEmpty(message.length === 0);
  };

  return (
    <FormWrapper layout="vertical" form={form} onValuesChange={handleValuesChange}>
      <Form.Item label="SMS (Text) Message" name="message">
        <TextArea showCount maxLength={140} style={{ height: '80px' }} />
      </Form.Item>
      <Form.Item>
        <div className="btn-container">
          <Button
            type="primary"
            htmlType="button"
            disabled={isFormEmpty || isSMSButtonDisabled}
            onClick={() => handleSendNotification({ message: form.getFieldValue('message') })}>
            Send Message
          </Button>
          <p>*Click only once</p>
        </div>
      </Form.Item>
    </FormWrapper>
  );
};
