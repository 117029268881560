import { Button, Form, Input, notification } from 'antd';
import TextEditor from 'common/components/form-elements/editor/editor';
import { EditorProps } from 'common/components/form-elements/editor/editor.types';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import notificationService from 'common/services/notification-service/notification-service';
import { INotificationMessage } from 'common/services/notification-service/notification-service.types';
import Quill from 'quill';
import { useRef, useState } from 'react';

export const PushNotifications = () => {
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [textWithoutHtml, setTextWithoutHtml] = useState<string>('');
  const [isPushButtonDisabled, setIsPushButtonDisabled] = useState<boolean>(false);

  const quillRef = useRef<Quill | null>(null);

  const handleTextChange = (content: string) => {
    setMessage(content);
  };

  const handleQuillChange = () => {
    if (quillRef.current) {
      const content = quillRef.current.root.innerHTML;
      setTextWithoutHtml(quillRef.current.getText().trim());
      handleTextChange(content);
    }
  };

  const handleSendNotification = async () => {
    try {
      setIsPushButtonDisabled(true);

      const messageWithTitle: INotificationMessage = {
        message: message,
        subject: subject,
      };
      await notificationService.sendPushMassNotification(messageWithTitle);

      setIsPushButtonDisabled(false);
      notification.success({
        message: 'Success',
        description: 'Push notification sent successfully!',
        duration: 1.5,
      });
    } catch (error) {
      console.error('Failed to send push notification:', error);
      setIsPushButtonDisabled(false);
    }
  };

  const PushEditorProps: EditorProps = {
    readOnly: false,
    onTextChange: handleQuillChange,
    maxLength: 225,
    toolbarOptions: {
      enableLink: true,
      enableClean: true,
    },
  };

  return (
    <FormWrapper layout="vertical">
      <Form.Item label="Push Notification Subject">
        <Input showCount maxLength={50} name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
      </Form.Item>
      <Form.Item label="Push Notification Message">
        <TextEditor {...PushEditorProps} ref={quillRef} />
      </Form.Item>
      <Form.Item>
        <div className="btn-container">
          <Button
            type="primary"
            htmlType="button"
            onClick={handleSendNotification}
            disabled={subject.length === 0 || textWithoutHtml.length === 0 || isPushButtonDisabled}>
            Send Notification
          </Button>
          <p>*Click only once</p>
        </div>
      </Form.Item>
    </FormWrapper>
  );
};
