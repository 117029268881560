import { ServerClient } from 'common/utils/http/http-clients';
import { INotificationChannel } from '../residents-service/residents.service.types';
import { NotificationType } from 'common/enums/notification-type.enum';
import { INotificationMessage } from './notification-service.types';

const MASS_NOTIFICATION = '/mass/';

class NotificationService {
  async sendEmailMassNotification(model: INotificationMessage): Promise<void> {
    const res = await ServerClient.put<void>(MASS_NOTIFICATION + 'email', model);
    return res?.data;
  }

  async sendPushMassNotification(model: INotificationMessage): Promise<void> {
    const res = await ServerClient.put<void>(MASS_NOTIFICATION + 'push', model);
    return res?.data;
  }

  async sendSMSMassNotification(message: string): Promise<void> {
    const res = await ServerClient.put<void>(MASS_NOTIFICATION + 'sms', {
      message,
    });
    return res?.data;
  }

  getPreferredNotificationType(notificationChannels?: INotificationChannel[]): NotificationType {
    if (!notificationChannels) return NotificationType.Undefined;

    const preferredChannel = notificationChannels.find((channel: INotificationChannel) => channel.isPreferred === true);
    return preferredChannel?.notificationType ?? NotificationType.Undefined;
  }
}

export const notificationService = new NotificationService();
export default notificationService;
