import { Card, Col, Divider, Row } from 'antd';
import { EmailNotifications } from './components/email-notifications';
import { PushNotifications } from './components/push-notifications';
import { SMSNotifications } from './components/sms-notifications';
import featureStore from 'common/stores/features/feature.store';
import './mass-notifications.styles.scss';

export const MassNotifications = () => {
  return (
    <Card className="eiq-card-border">
      <Row className="eiq-card-header row-column">
        <Col>
          <h1 className="h1">EIQ-Alerts</h1>
        </Col>
        <h3 className="h3">
          This section allows you to send messages to <b>all residents</b> in your community. Due to the differences of maximum allowed
          characters, all three types of messages should be sent separately.
        </h3>
      </Row>

      <Divider orientation="right">Send an Email Message</Divider>
      <EmailNotifications />

      <Divider orientation="right">Send a Push Notification (EIQ-Connect App Notification)</Divider>
      <PushNotifications />

      {featureStore.isSMSMassNotificationsEnabled && (
        <>
          <Divider orientation="right">Send a SMS (Text) Notification</Divider>
          <SMSNotifications />
        </>
      )}
    </Card>
  );
};
