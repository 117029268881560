import React, { FC } from 'react';
import { IAlertBlock } from './alert-block.types';
import './alert-block.scss';

const AlertBlock: FC<IAlertBlock> = ({ alertHeader, alertMessage }) => {
  return (
    <div className={`alert-block-container`}>
      <span>{!!alertHeader ? alertHeader : 'ALERT!'}</span>
      <span className={`${alertMessage && alertMessage?.length > 0 ? '' : ''}`}>{alertMessage ?? 'No alert'}</span>
    </div>
  );
};

export default AlertBlock;
